import { findElement } from "./utils/find_element";

/**
 * Sets up Google Places Autocomplete on an input field and updates related fields with address components.
 */
export const AddressAutoComplete = () => {
    const input = findElement('address[-_]?line[-_]?');
    if (!input) return;

    const autocomplete = new google.maps.places.Autocomplete(input, { componentRestrictions: { country: 'au' } });
    autocomplete.addListener('place_changed', () => updateAddressFields(autocomplete));
};

/**
 * Updates city, state, and postcode input fields based on the selected place's address components.
 * @param {google.maps.places.Autocomplete} autocomplete The autocomplete instance.
 */
function updateAddressFields(autocomplete) {
    const place = autocomplete.getPlace();
    if (!place.address_components) {
        console.error('No address components found.');
        return;
    }

    const addressLine1 = findElement('address[-_]?line[-_]?');
    const addressMapping = {
        'locality': findElement('city'),
        'administrative_area_level_1': findElement('state'),
        'postal_code': findElement('postcode'),
        'street_number': 'street',
        'route': 'street'
    };

    let streetAddress = '';

    place.address_components.forEach(component => {
        const addressType = component.types.find(type => addressMapping[type]);
        if (addressType) {
            const inputField = addressMapping[addressType];

            if (addressType === 'administrative_area_level_1') {
                inputField.value = component.short_name.toLowerCase();
            } else if (inputField && inputField !== 'street') {
                inputField.value = component.long_name;
            } else if (inputField === 'street') {
                streetAddress += component.long_name + ' ';
            }
        }
    });

    if (addressLine1 && streetAddress.trim()) {
        addressLine1.value = streetAddress.trim();
    }
}