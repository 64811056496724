import {Controller} from "stimulus"
import {getRequest, postRequest} from "../helpers/api";
import {debounce} from "../helpers/debounce";

export default class extends Controller {
    static targets = ["provider", "providersName", "holiday", "mainCategory", "category", "activity", "billableItem", "startDate", "startTime", "activeSupport",
        "duration", "faceToFace", "hintText", "add_item", "template", "nestedFields", "itemOptions", "itemDuration", "itemStart", "itemEnd",
        "itemStartTime", "itemEndTime", "sleepover", "groupSessionContainer", "groupSessionMembers", "serviceType", "wrongGroupBillableMessage", "buddyShift", "buddyShiftRadioNo", "buddyShiftRadioYes", "submit"];

    connect() {
        // On load, check the buddy shift visibility.
        // Assume if new, we have no provider selected yet and no radios to be checked
        // Assume if edit, the provider is already selected and we don't need to check the radios
        this.checkBuddyShiftVisibility(false)
    }

    checkPublicHoliday() {
        const dateValue = this.startDateTarget.value;

        if (dateValue) {
            const date = new Date(`${dateValue}`);

            const urlParams = new URLSearchParams(window.location.search);
            const memberId = urlParams.get('member_id');

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            fetch(`/api/v2/progress_notes/check_public_holiday?date=${formattedDate}&member_id=${memberId}`)
                .then(response => response.json())
                .then(data => {
                    let checkboxChanged = this.holidayTarget.checked !== data.is_public_holiday;
                    this.holidayTarget.checked = data.is_public_holiday;
                    return checkboxChanged
                })
                .then((checkboxChanged) => {
                    // find billables AFTER the checkbox value is changed
                    if (checkboxChanged) {
                        this.handleSelectActivity()
                    }
                });
        }
    }

    handleSelectMainCategory() {
        this.handleSelectChange(this.mainCategoryTarget.value, "category_id")
    }

    handleSelectSubcategory() {
        this.handleSelectChange(this.categoryTarget.value, "activity_id")
    }

    handleSelectChange(value, populate) {
        const categoryBox = this.categoryTarget
        const activityBox = this.activityTarget
        // const billableItemsBox = this.billableItemTarget
        // const billableItemElement = document.getElementById("billable-item")
        const populateBox = document.getElementById(`progress_note_${populate}`)

        clearPopulateBox(populateBox)
        this.hintTextTarget.classList.add("hidden")

        if (value == "") {
            addOption(populateBox, "", "Select Category")
        }


        fetch(this.data.get("url"), {
            method: 'POST',
            body: JSON.stringify({
                category_id: value,
                start_time: `${this.startDateTarget.value} ${this.startTimeTarget.value}`,
                duration: this.durationTarget.value,
                face_to_face: this.faceToFaceTarget.value,
            }),
            credentials: 'include',
            dataType: 'script',
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token"),
                "Content-Type": "application/json"
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    const blankOption = document.createElement('option');
                    populateBox.appendChild(blankOption);
                    data.forEach(item => {
                        addOption(populateBox, item.id, item.name)
                    });
                } else {
                    this.hintTextTarget.classList.remove("hidden")
                    addOption(populateBox, "", "No Category")
                    this.removeBillableItems()
                }
            })

        if (categoryBox.value == "") {
            clearPopulateBox(activityBox)
            addOption(activityBox, "", "Select Category")
        }
    }

    handleSelectActivity() {
        // this.requestsCount is a global variable - it persists while the session lasts
        this.requestsCount = (this.requestsCount || 0) + 1
        let this_request_number = this.requestsCount

        const groupSessionsValue = this.groupSessionMembers()

        fetch(this.data.get("billables"), {
            method: 'POST',
            body: JSON.stringify({
                activity_id: this.activityTarget.value,
                start_time: `${this.startDateTarget.value} ${this.startTimeTarget.value}`,
                duration: this.durationTarget.value,
                face_to_face: this.faceToFaceTarget.value,
                holiday: this.holidayTarget.checked,
                active_support: this.activeSupportTarget.value,
                group_member_ids: groupSessionsValue || null
            }),
            credentials: 'include',
            dataType: 'script',
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token"),
                "Content-Type": "application/json"
            },
        })
            .then(response => response.json())
            .then(data => {
                // Consider multiple requests being sent (multiclick)
                // eg (1st req) this_request_number = 1 -> requestsCount = 1
                //    (2nd req) this_request_number = 2 -> requestsCount = 2
                // if requestsCount is 2, then we should only process the response of the request 2
                if (this_request_number === this.requestsCount) {
                    this.processData(data)
                }
                // else - do nothing if the response belongs to the previous request (this_request_number = 1)
            })
    }

    processData(data) {
        if (data.length > 0) {
            this.removeBillableItems()

            data.forEach(billable_item => {
                this.addBillableItem(billable_item)
            });

            this.hintTextTarget.classList.add("hidden")
        } else {
            this.hintTextTarget.classList.remove("hidden")
            this.removeBillableItems()
        }
    }

    addBillableItem(billable_item_with_time) {
        // Adds billable item row
        // event.preventDefault()
        var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)


        for (let i = 0; i < this.itemDurationTargets.length; i++) {
            if (!this.itemDurationTargets[i].value) {
                this.itemDurationTargets[i].value = billable_item_with_time['duration']
                this.itemStartTargets[i].value = billable_item_with_time['start_hour']
                this.itemEndTargets[i].value = billable_item_with_time['end_hour']
                this.itemStartTimeTargets[i].value = billable_item_with_time['start_time']
                this.itemEndTimeTargets[i].value = billable_item_with_time['end_time']
                if (billable_item_with_time['sleepover'] == true) {
                    this.sleepoverTargets.forEach(el => {
                        el.classList.remove('hidden')
                    })
                } else {
                    this.sleepoverTargets.forEach(el => {
                        el.classList.add('hidden')
                    })
                }

                billable_item_with_time['billable_items'].forEach(billable_item => {
                    addOption(this.itemOptionsTargets[i], billable_item.id,
                        `${billable_item.description} - ${billable_item.code} `
                    )
                });
            }
        }

    }

    removeBillableItems() {
        // Removes all billable items
        this.nestedFieldsTargets.forEach((el) => {
            el.remove();
        });
    }

    groupSessionMembers() {
        // Get the group members if they exist
        let groupSessionsValue = this.groupSessionMembersTarget.value
        if (groupSessionsValue) {

            if (groupSessionsValue === "[]") {
                groupSessionsValue = null
            } else {
                // Make sure they're a valid int array. It may come in like ["1", 2, "3"] so we need to convert it to [1, 2, 3]
                groupSessionsValue = JSON.parse(groupSessionsValue).map(i => parseInt(i))
            }

        }

        return groupSessionsValue;
    }

    /**
     * Checks the visibility of the buddy shift section and updates the radio buttons accordingly.
     *
     * @param {boolean} [checkRadios=true] - Flag to determine whether to update the radio buttons.
     */
    checkBuddyShiftVisibility(checkRadios = true) {
        // Get the provider select element
        const providerSelect = document.getElementById("progress_note_provider_id");

        // Make a GET request to check if the current provider needs to see the buddy shift question

        debounce(getRequest(`/api/v2/providers/${providerSelect.value}/buddy_shift_visibility`, 'buddy_shift_visibility')
            .then(response => {
                const providerName = providerSelect.options[providerSelect.selectedIndex].text;
                this.handleBuddyShiftVisibility(checkRadios, response.show_buddy_shift ?? false, response.is_checked ?? false, providerName);
            })
            .catch((error) => {
                console.error(error);
                this.handleBuddyShiftVisibility(checkRadios, false, false, "");
            }), 500);
    }

    handleBuddyShiftVisibility(checkRadios, showBuddyShift, isChecked, providerName) {
        // If the response indicates the buddy shift should be shown
        if (showBuddyShift) {
            // Remove the hidden class to show the buddy shift section
            this.buddyShiftTarget.classList.remove("hidden");

            this.providersNameTarget.innerText = providerName;
            if (!checkRadios) return;

            this.buddyShiftRadioYesTarget.checked = isChecked;
            this.buddyShiftRadioNoTarget.checked = !isChecked;
        } else {
            // Add the hidden class to hide the buddy shift section
            this.buddyShiftTarget.classList.add("hidden");

            // Default the buddy shifts to be checked "No" if there it's hidden
            this.buddyShiftRadioYesTarget.checked = false;
            this.buddyShiftRadioNoTarget.checked = true;
        }
    }

}

function getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
}

function addOption(populateBox, value, text) {

    const opt = document.createElement('option');
    opt.value = value
    opt.text = text
    populateBox.appendChild(opt);
}

function clearPopulateBox(populateBox) {
    for (const option of [...populateBox.options]) {
        option.remove();
    }
}
