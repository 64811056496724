import {Controller} from "stimulus";
import SessionService from "../services/session_service";
import {getRequest, postRequest} from "../helpers/api";

/**
 * Constants for session expiry handling
 */
const INITIAL_POLL_INTERVAL = 30; // Starting interval of 30 seconds
const MIN_POLL_INTERVAL = 2; // Minimum poll interval of 2 seconds
const POLL_MAX_ATTEMPTS = 30; // Max attempts
const ALMOST_EXPIRING = 60 * 5; // 5 minutes
const EXPIRY_POLL_INTERVAL = 5; // 5 seconds
const INACTIVITY_THRESHOLD = 30000; // 30 seconds of inactivity

/**
 * SessionExpiryController class handles session expiry logic.
 * It fetches the session expiry time from the server and displays a countdown timer.
 * When the session is almost expired, it shows a modal to warn the user.
 * If the session has expired, it shows a modal to prompt the user to log back in.
 * The controller also manages polling across tabs and broadcasts session updates.
 * @extends Controller
 */
export default class extends Controller {
    static targets = ["timer", "almostExpiredModal", "expiredModal", "manualRefresh"];

    sessionService = new SessionService({getRequest, postRequest});
    expiration = null;
    countdownTimer = null;
    expiredTimer = null;
    pollInterval = INITIAL_POLL_INTERVAL;
    showingExpired = false;
    currentPollAttempts = 0;
    lastActivityTime = Date.now();
    debounceTimer = null;
    isPollingTab = false;
    sessionExpiryBroadcast = null;
    isFetchingExpiry = false;

    /**
     * Called when the controller is connected to the DOM.
     * Sets up the broadcast channel, user activity handlers, and polling tab check.
     */
    connect() {
        this.setupBroadcastChannel();
        this.handleUserActivity();
        this.checkPollingTab();
        this.syncWithLocalStorage();
        this.getExpiry(true); // Fetch expiry immediately on connect for new tabs to update the state of all tabs
        document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
        window.addEventListener('storage', this.handleStorageChange.bind(this)); // Sync when localStorage changes
    }

    /**
     * Called when the controller is disconnected from the DOM.
     * Cleans up event listeners and closes the broadcast channel.
     */
    disconnect() {
        this.closeBroadcastChannel();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
        window.removeEventListener('mousemove', this.updateLastActivity);
        window.removeEventListener('keydown', this.updateLastActivity);
        window.removeEventListener('storage', this.handleStorageChange);
    }

    /**
     * Sets up the BroadcastChannel for session expiry communication.
     */
    setupBroadcastChannel() {
        // Reinitialize the BroadcastChannel if it was closed or not yet created
        if (!this.sessionExpiryBroadcast || this.sessionExpiryBroadcast.closed) {
            this.sessionExpiryBroadcast = new BroadcastChannel('session-expiry');
            this.sessionExpiryBroadcast.onmessage = (event) => {
                if (event.data.type === 'expiry-update') {
                    this.handleSessionUpdate(event.data.expiration);
                } else if (event.data.type === 'refresh-request') {
                    this.syncWithLocalStorage();
                }
            };
        }
    }

    /**
     * Closes the BroadcastChannel.
     */
    closeBroadcastChannel() {
        if (this.sessionExpiryBroadcast) {
            this.sessionExpiryBroadcast.close();
            this.sessionExpiryBroadcast = null;
        }
    }

    /**
     * Checks if the current tab is the polling tab and sets it if not.
     */
    checkPollingTab() {
        const pollingTab = localStorage.getItem('pollingTab');
        if (!pollingTab || pollingTab === window.name) {
            localStorage.setItem('pollingTab', window.name);
            this.isPollingTab = true;
            this.getExpiry();
            window.addEventListener('beforeunload', this.releasePollingTab.bind(this));
        } else {
            // Regularly check if the polling tab is still active
            setInterval(() => {
                const activePollingTab = localStorage.getItem('pollingTab');
                if (!activePollingTab || activePollingTab === window.name) {
                    this.checkPollingTab();
                }
            }, 5000);
        }
    }

    /**
     * Releases the polling tab when the window is unloaded.
     */
    releasePollingTab() {
        if (this.isPollingTab) {
            localStorage.removeItem('pollingTab');
        }
    }

    /**
     * Sets up event listeners for user activity and adjusts poll interval based on activity.
     * The poll interval is halved when the user is active and doubled when inactive.
     */
    handleUserActivity() {
        window.addEventListener('mousemove', this.updateLastActivity.bind(this));
        window.addEventListener('keydown', this.updateLastActivity.bind(this));

        setInterval(() => {
            if (Date.now() - this.lastActivityTime > INACTIVITY_THRESHOLD) {
                this.pollInterval = Math.min(INITIAL_POLL_INTERVAL * 2, this.pollInterval);
            } else {
                this.pollInterval = Math.max(MIN_POLL_INTERVAL, this.pollInterval);
            }
        }, 5000);
    }

    /**
     * Updates the last activity time to the current time.
     */
    updateLastActivity() {
        this.lastActivityTime = Date.now();
    }

    /**
     * Handles visibility change events to manage session expiry checks.
     * When the tab is visible, it syncs with localStorage and requests the session expiry.
     * When the tab is hidden, it clears the timers to stop checking the session expiry.
     */
    handleVisibilityChange() {
        if (!document.hidden) {
            this.setupBroadcastChannel(); // Ensure the channel is open when the tab becomes visible
            this.syncWithLocalStorage();
            if (this.isPollingTab) {
                this.getExpiry();
            }
            this.sendBroadcastMessage({type: 'refresh-request'});
        } else {
            this.clearTimers();
        }
    }

    /**
     * Handles storage change events to sync session expiry across tabs.
     * When the session expiration changes in localStorage, it updates the session expiry.
     * @param {StorageEvent} event - The storage event.
     */
    handleStorageChange(event) {
        if (event.key === 'sessionExpiration') {
            this.handleSessionUpdate(Number(event.newValue));
        } else if (event.key === 'broadcastMessage') {
            const message = JSON.parse(event.newValue);
            if (message) {
                if (message.type === 'expiry-update') {
                    this.handleSessionUpdate(message.expiration);
                } else if (message.type === 'refresh-request') {
                    this.syncWithLocalStorage();
                }
            }
        }
    }

    /**
     * Clears the countdown and expired timers.
     */
    clearTimers() {
        if (this.countdownTimer) clearInterval(this.countdownTimer);
        if (this.expiredTimer) clearTimeout(this.expiredTimer);
    }

    /**
     * Fetches the session expiry time from the server.
     */
    getExpiry(overridePollingTab = false, showWindows = false) {
        if (!this.isPollingTab && !overridePollingTab) return;
        if (this.isFetchingExpiry) return; // Prevent multiple concurrent calls

        this.isFetchingExpiry = true; // Set the flag to indicate fetching is in progress
        this.clearTimers();
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            this.sessionService.getExpiry()
                .then((response) => {

                    // Response failed so either something is wrong with the server or the user is not authenticated
                    if (response.failed || response.status === 401) {
                        this.handleUnsuccessfulResponse();
                        return;
                    }

                    const {expires_in} = response;
                    this.expiration = expires_in;

                    localStorage.setItem('sessionExpiration', this.expiration);
                    this.sendBroadcastMessage({type: 'expiry-update', expiration: this.expiration});

                    if (showWindows) return;

                    // Session Expiring
                    if (this.expiration < ALMOST_EXPIRING) {
                        this.showAlmostExpiring();
                    }

                    // Session expired
                    if (this.expiration <= 0) {
                        this.showExpired();
                        return;
                    }

                    // Schedule almost expired modal
                    if (this.expiration > ALMOST_EXPIRING) {
                        setTimeout(() => this.showAlmostExpiring(), (this.expiration - ALMOST_EXPIRING) * 1000);
                    }

                    if (this.showingExpired) {
                        this.scheduleNextExpiryCheck();
                    }

                })
                .catch((error) => {
                    this.handleUnsuccessfulResponse();
                    this.scheduleNextExpiryCheck();
                })
                .finally(() => {
                    this.isFetchingExpiry = false; // Reset the flag after fetching is complete
                });
        }, 300);
    }

    handleUnsuccessfulResponse() {
        localStorage.setItem('sessionExpiration', 0);
        this.sendBroadcastMessage({type: 'expiry-update', expiration: 0});
        this.showExpired();
    }

    /**
     * Schedules the next expiry check based on the current poll interval.
     */
    scheduleNextExpiryCheck() {
        this.pollInterval = Math.max(MIN_POLL_INTERVAL, this.pollInterval / 2);
        clearTimeout(this.expiredTimer);
        this.expiredTimer = setTimeout(() => this.getExpiry(), this.pollInterval * 1000);
    }

    /**
     * Refreshes the session by making a request to the server.
     */
    refreshSession() {
        this.clearTimers();
        this.sessionService.refreshSession()
            .then((response) => {

                // Response failed so either something is wrong with the server or the user is not authenticated
                if (response.failed || response.status === 401) {
                    this.handleUnsuccessfulResponse();
                    return;
                }

                // Hide the refresh button
                this.manualRefreshTarget.classList.add("hidden");
                setTimeout(() => this.manualRefreshTarget.classList.remove("hidden"), 5000);

                this.almostExpiredModalTarget.classList.add("hidden");

                if (response.ok && this.showingExpired) {
                    this.expiredModalTarget.classList.add("hidden");
                    this.showingExpired = false;
                    this.pollInterval = INITIAL_POLL_INTERVAL;
                    this.getExpiry();
                    return;
                }

                if (response.failed && this.showingExpired) {
                    this.currentPollAttempts += 1;
                    if (this.currentPollAttempts <= POLL_MAX_ATTEMPTS) {
                        this.expiredTimer = setTimeout(() => this.refreshSession(), EXPIRY_POLL_INTERVAL * 1000);
                        this.showExpired();
                    }
                    return;
                }

                this.getExpiry();
            })
            .catch((error) => {
                console.error("Error refreshing session:", error);
                this.handleUnsuccessfulResponse();
            })
            .catch((error) => {
                console.error("Error refreshing session:", error);
            });
    }

    /**
     * Displays the almost expired modal and starts the countdown timer.
     */
    showAlmostExpiring() {
        this.clearTimers();
        this.expiredModalTarget.classList.add("hidden");
        this.almostExpiredModalTarget.classList.remove("hidden");
        this.updateTimer();
        this.countdownTimer = setInterval(() => this.updateTimer(), 1000);
    }

    /**
     * Displays the expired modal and stops the countdown timer.
     */
    showExpired() {
        this.clearTimers();
        this.almostExpiredModalTarget.classList.add("hidden");
        this.expiredModalTarget.classList.remove("hidden");
        this.showingExpired = true;
    }

    /**
     * Opens the login page in a new tab and attempts to refresh the session.
     */
    logBackIn() {
        window.open("/providers/sign_in", "_blank");
        this.clearTimers();
        this.expiredTimer = setTimeout(() => this.refreshSession(), EXPIRY_POLL_INTERVAL * 1000);
    }

    /**
     * Logs the user out by redirecting to the logout page.
     * Reset the session expiration to 0 to prevent further polling.
     */
    logout() {
        this.sendBroadcastMessage({type: 'expiry-update', expiration: 0});
        window.location.href = "/providers/auth/sign_out";
    }

    /**
     * Closes the current tab.
     */
    closeTab() {
        window.close();
    }

    /**
     * Updates the timer display with the remaining session time.
     */
    updateTimer() {
        if (!this.expiration || this.expiration <= 0) {
            this.clearTimers();
            this.showExpired();
            return;
        }

        const seconds = Math.floor(this.expiration % 60);
        const minutes = Math.floor((this.expiration % (60 * 60)) / 60);

        const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;
        const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

        this.timerTarget.textContent = `${displayMinutes}:${displaySeconds}`;
        this.expiration -= 1;
    }

    /**
     * Syncs the session expiry time with the value stored in localStorage.
     */
    syncWithLocalStorage() {
        const storedExpiration = localStorage.getItem('sessionExpiration');
        if (storedExpiration) {
            this.handleSessionUpdate(Number(storedExpiration));
        }
    }

    /**
     * Handles session update messages from the BroadcastChannel.
     * @param {number} expiration - The new session expiration time.
     */
    handleSessionUpdate(expiration) {
        this.expiration = expiration;
        if (this.expiration <= 0) {
            this.showExpired();
        } else if (this.expiration < ALMOST_EXPIRING) {
            this.showAlmostExpiring();
        } else {
            this.pollInterval = INITIAL_POLL_INTERVAL;
            this.almostExpiredModalTarget.classList.add("hidden");
            this.expiredModalTarget.classList.add("hidden");
            this.clearTimers();
            this.updateTimer();
        }
    }

    /**
     * Sends a message through the BroadcastChannel.
     * @param {Object} message - The message to send.
     */
    sendBroadcastMessage(message) {
        try {
            if (this.sessionExpiryBroadcast && !this.sessionExpiryBroadcast.closed) {
                this.sessionExpiryBroadcast.postMessage(message);
            } else {
                this.setupBroadcastChannel(); // Reinitialize if closed
                this.sessionExpiryBroadcast.postMessage(message);
            }
        } catch (error) {
            // Browser is quite outdated or does not support BroadcastChannel
            console.error("Error sending broadcast message:", error);
            this.fallbackToLocalStorage(message);
        }
    }

    /**
     * Fallback to localStorage for message passing.
     * @param {Object} message - The message to send.
     */
    fallbackToLocalStorage(message) {
        try {
            localStorage.setItem('broadcastMessage', JSON.stringify(message));
            localStorage.removeItem('broadcastMessage'); // Trigger storage event
        } catch (error) {
            console.error("Error using localStorage as fallback:", error);
        }
    }
}